import dayjs from "dayjs";

const checkIsAge = (date: string, age: number) => {
  const dateError = checkIsDate(date, "DD.MM.YYYY");
  if (dateError) {
    return dateError;
  }
  const splitInput = date.split(".");
  const day = parseInt(splitInput[0]);
  const month = parseInt(splitInput[1]) - 1;
  const year = parseInt(splitInput[2]);
  const parsedDate = new Date(year, month, day);
  const today = new Date();
  const isAgeDate = new Date(
    today.getFullYear() - age,
    today.getMonth(),
    today.getDate()
  ).getTime();
  const isTooYoung = parsedDate.getTime() - isAgeDate > 0;
  if (isTooYoung) {
    return `Du musst ${age} Jahre alt sein, um hier fortzufahren, oder komm mit deinen Eltern persönlich ins Studio.`;
  }
  return null;
};

const checkIsDate = (date: string, format: string) => {
  let splitInput: any[] = date.split(".");
  const day = parseInt(splitInput[0]);
  const month = parseInt(splitInput[1]) - 1;
  const year = parseInt(splitInput[2]);
  const parsedDate = new Date(year, month, day);
  if (parsedDate.toString() === "Invalid Date") {
    return "Datum muss in diesem Format angegeben werden: TT.MM.JJJJ";
  }
  const validDate = dayjs(parsedDate).format(format) === date;
  if (!validDate) {
    return "Datum muss gültig sein und in diesem Format angegeben werden: TT.MM.JJJJ";
  }
  return null;
};

const checkisFirstOfMonth = (date: string) => {
  let splitInput: any[] = date.split(".");
  const day = parseInt(splitInput[0]);
  const month = parseInt(splitInput[1]) - 1;
  const year = parseInt(splitInput[2]);
  const parsedDate = new Date(year, month, day);
  if (parsedDate.toString() === "Invalid Date") {
    return "Datum muss in diesem Format angegeben werden: TT.MM.JJJJ";
  }
  const validDate = dayjs(parsedDate).date() === 1;
  if (!validDate) {
    return "Das Datum muss der Erste Tag des jeweiligen Monats nach Trainingsbeginn sein – bspw. Trainingsbeginn: 25.03.2022 & Vertragsbeginn: 01.04.2022";
  }
  return null;
};
/*
 * Returns 1 if the IBAN is valid
 * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
 * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
 */
function isValidIBANNumber(input: string) {
  var CODE_LENGTHS: { [key: string]: number } = {
    AD: 24,
    AE: 23,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BG: 22,
    BH: 22,
    BR: 29,
    CH: 21,
    CR: 21,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    EE: 20,
    ES: 24,
    FI: 18,
    FO: 18,
    FR: 27,
    GB: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IS: 26,
    IT: 27,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MD: 24,
    ME: 22,
    MK: 19,
    MR: 27,
    MT: 31,
    MU: 30,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    TN: 24,
    TR: 26,
    AL: 28,
    BY: 28,
    EG: 29,
    GE: 22,
    IQ: 23,
    LC: 32,
    SC: 31,
    ST: 25,
    SV: 28,
    TL: 23,
    UA: 29,
    VA: 22,
    VG: 24,
    XK: 20,
  };
  let iban = String(input)
    .toUpperCase()
    .replace(/[^A-Z0-9]/g, ""); // keep only alphanumeric characters
  let code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/); // match and capture (1) the country code, (2) the check digits, and (3) the rest
  let digits;
  if (input.includes(" ")) {
    return "IBAN darf keine Leerzeichen enthalten";
  }
  // check syntax and length
  if (!code || !code[1]) {
    return "Keine gültige IBAN";
  }
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return `IBAN muss ${CODE_LENGTHS[code[1]]} Zeichen lang sein`;
  }
  // rearrange country code and check digits, and convert chars to ints
  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter) => {
    return (letter.toString().charCodeAt(0) - 55).toString();
  });
  // final check
  if (mod97(digits) !== 1) {
    return "Keine gültige IBAN";
  }
  return null;
}

function mod97(string: string) {
  let checksum: any = string.slice(0, 2),
    fragment;
  for (var offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
}

export const validate = (data: { [key: string]: any }, validations: any) => {
  const errors: { [key: string]: any } = {};
  Object.keys(validations).forEach((id) => {
    const currentInput = data[id];
    for (const validation in validations[id]) {
      if (errors[id]) {
        return;
      }
      switch (validation) {
        case "required":
          if (
            !currentInput ||
            !currentInput.value ||
            (typeof currentInput.value === "string" &&
              !currentInput.value.trim())
          ) {
            errors[id] = "Dieses Feld wird benötigt.";
            return;
          }
          break;
        case "isEmail":
          if (
            currentInput?.value &&
            !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(
              currentInput.value
            )
          ) {
            errors[id] = "Ups... Diese Email ist nicht gültig.";
            return;
          }
          break;
        case "isDate":
          if (currentInput?.value) {
            const dateErrors = checkIsDate(currentInput.value, "DD.MM.YYYY");
            if (dateErrors) {
              errors[id] = dateErrors;
              return;
            }
          }
          break;
        case "isFirstOfMonth":
          if (currentInput?.value) {
            const isFirstOfMonthError = checkisFirstOfMonth(currentInput.value);
            if (isFirstOfMonthError) {
              errors[id] = isFirstOfMonthError;
              return;
            }
          }
          break;
        case "isAge":
          if (currentInput?.value) {
            const ageErrors = checkIsAge(
              currentInput.value,
              validations[id][validation]
            );
            if (ageErrors) {
              errors[id] = ageErrors;
              return;
            }
          }
          break;
        case "isIBAN":
          if (currentInput?.value) {
            const ibanErrors = isValidIBANNumber(currentInput.value);
            if (ibanErrors) {
              errors[id] = ibanErrors;
              return;
            }
          }
      }
    }
  });
  return errors;
};
