import Link from "next/link";

const Footer = () => {
  return (
    <div className="full-width bg-gray-100 py-8">
      <div className="max-w-6xl text-center text-gray-600 mx-auto">
        <div className="flex justify-center">
          <div className="p-4">
            <Link href="/impressum">
              <a className="hover:text-primary">Impressum</a>
            </Link>
          </div>
          <div className="p-4">
            <Link href="/datenschutz">
              <a className="hover:text-primary">Datenschutz</a>
            </Link>
          </div>
          <div className="p-4">
            <Link href="/agb">
              <a className="hover:text-primary">AGB</a>
            </Link>
          </div>
        </div>
        <p className=" text-sm">Copyright Ghostform 2020</p>
      </div>
    </div>
  );
};

export default Footer;
