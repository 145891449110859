import { createCookie } from "./auth-cookies";

type AuthProps = {
  email: string;
  password: string;
  invitationCode?: string;
};
type SubmissionAuthProps = {
  id: string;
  password: string;
};

export async function betaSignUp(email: string) {
  return fetch("/api/auth/betaTester", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
    }),
  }).then(async (res) => {
    if (res.status === 403) {
      const error = new Error("Diese E-Mail wird bereits verwendet.");
      // @ts-ignore
      error.status = 403;
      throw error;
    } else {
      const success = await res.json();
      return success;
    }
  });
}
export async function signUp({ email, password, invitationCode }: AuthProps) {
  return fetch("/api/auth/signup", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      password,
      invitationCode,
    }),
  }).then(async (res) => {
    if (res.status === 403) {
      const errorMsg = await res.text();
      console.log(errorMsg);
      const error = new Error(errorMsg ?? "Keine Berechtigung");
      // @ts-ignore
      error.status = 403;
      throw error;
    } else {
      const user = await res.json();
      return { user: user ?? null };
    }
  });
}

export async function signIn({ email, password }: AuthProps) {
  return fetch("/api/auth/signin", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      password,
    }),
  }).then(async (res) => {
    if (res.status === 403) {
      const json = await res.json();
      const error = new Error(json.message || "Not authorized!");
      // @ts-ignore
      error.status = 403;
      throw error;
    } else {
      const user = await res.json();
      return { user: user ?? null };
    }
  });
}
export async function signOut(mutate: Function) {
  await fetch("/api/auth/signout");
  const cookie = createCookie({
    name: "loggedOut",
    value: "1",
    options: { sameSite: "lax", path: "/" },
  });
  document.cookie = cookie;
  return mutate();
}

export async function verifySubmissionPassword({
  id,
  password,
}: SubmissionAuthProps) {
  return fetch("/api/auth/submission", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id,
      password,
    }),
  }).then(async (res) => {
    if (res.status === 403) {
      const error = new Error("Not authorized!");
      // @ts-ignore
      error.status = 403;
      throw error;
    } else {
      const data = await res.json();
      return data ?? null;
    }
  });
}
