import { useRouter } from "next/router";
import { FormEvent, useState } from "react";
import { betaSignUp } from "../../../lib/auth-fetcher";
import { getErrorMessage } from "../../../lib/form";
import { validate } from "../../../lib/validations/validate";
import Button from "../Button/Button";
import { TextFieldProps } from "./TextField";

export interface TextFieldWithButtonProps extends TextFieldProps {
  buttonText: string;
  onSubmit: Function;
}

export default function TextFieldWithButton({
  id,
  name,
  label,
  type,
  autoComplete,
  placeholder,
  showLabel,
  required,
  onChange,
  autoFocus,
  buttonText,
}: TextFieldWithButtonProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [successMsg, setSuccessMsg] = useState<string>("");
  const router = useRouter();
  const submitForm = async (event: any) => {
    event.preventDefault();
    if (isLoading) return;
    setIsLoading(true);
    const inputElement = event.currentTarget.elements[name];
    const data = { [name]: { value: inputElement.value } };
    const validations = { [name]: { required: true, isEmail: true } };
    const errors = validate(data, validations);
    if (Object.keys(errors).length > 0) {
      setErrorMsg(errors[name]);
      setIsLoading(false);
      return;
    }
    try {
      await betaSignUp(inputElement.value);
      setErrorMsg("");
      setSuccessMsg("Erfolgreich angemeldet. Du wirst sofort weitergeleitet!");
      router.push("/forms/demo/oB2IwnXyeB");
    } catch (error: any) {
      setErrorMsg(getErrorMessage(error));
      setSuccessMsg("");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form className="text-left w-full" onSubmit={submitForm}>
      <div className={`text-sm mb-2 ${!label || !showLabel ? "hidden" : null}`}>
        <label
          id={[name, "label"].join("-")}
          htmlFor={[name, "input"].join("-")}
        >
          {label}
        </label>
      </div>
      <div
        className={`text-left rounded-lg sm:rounded-text-field h-12 relative flex items-center border pr-2 sm:h-16 ${
          !!errorMsg ? "border-red-500" : "border-gray-300"
        } ${!!successMsg ? "border-green-500" : "border-gray-300"} `}
      >
        <input
          className={`input h-full w-full rounded-text-field pl-4 sm:pl-6 focus:outline-none`}
          autoComplete={autoComplete}
          id={[id, "input"].join("-")}
          name={name}
          placeholder={placeholder}
          required={required}
          type={type}
          autoFocus={autoFocus}
          onChange={onChange ? (e) => onChange(e.target.value, id) : () => null}
        ></input>
        <Button
          type="submit"
          variant="primary"
          size="small"
          className="flex-shrink-0"
          isLoading={isLoading}
        >
          {buttonText}
        </Button>
      </div>
      {!!errorMsg ? (
        <p className="mt-1 text-xs text-red-500">{errorMsg}</p>
      ) : null}
      {!!successMsg ? (
        <p className="mt-1 text-xs text-green-500">{successMsg}</p>
      ) : null}
    </form>
  );
}
