import Link from "next/link";
import Button from "../components/lib/Button/Button";
import Layout from "../components/lib/Layout/Layout";
import Navbar from "../components/lib/Navbar/Navbar";
import { Heading } from "../components/lib/Text/Heading";
import TextFieldWithButton from "../components/lib/TextField/TextFieldWithButton";
import { betaSignUp } from "../lib/auth-fetcher";

const Index = () => {
  return (
    <div className="theme-admin overflow-x-hidden overflow-y-hidden">
      <Navbar />
      <Layout>
        <div className="mt-8 flex flex-col w-full sm:flex-row sm:mt-16">
          <div className="max-w-2xl w-full flex flex-col items-center text-center sm:items-start sm:text-left">
            <div className="bg-primary-light text-primary p-1 pr-4 rounded-full mb-4">
              <span className="bg-primary text-white px-2 py-1 rounded-full mr-2">
                Verträge
              </span>
              sollten einfacher sein.
            </div>
            <Heading
              level={1}
              className="normal-case tracking-tighter mb-4 font-bold"
            >
              <span className="text-primary">Online Verträge erstellen.</span>
              <br />
              <span className="text-primary-light">
                Kein lästiger Papierkram.
              </span>
              <br />
              <span className="text-primary-dark">
                Mehr Zeit für die wirklich wichtigen Dinge.
              </span>
            </Heading>
            <p className="mb-8">
              Melde dich jetzt an und erfahre selbst, wie einfach & schnell du
              in Zukunft deine Verträge abschließen könntest.
            </p>
            <div className="mb-16 hidden sm:block sm:w-4/5">
              <TextFieldWithButton
                placeholder="Email eingeben & Zugang zur Demo erhalten"
                label="Email"
                name="email"
                showLabel={false}
                buttonText="Demo Anschauen"
                onSubmit={betaSignUp}
              />
            </div>
            <div className="mb-16 sm:hidden">
              <Link href="/beta-signup">
                <Button elementType="a" variant="primary">
                  Jetzt Starten
                </Button>
              </Link>
            </div>
            <div>
              <div className="flex flex-col items-center font-medium mb-4 sm:flex-row">
                <img src="/checkmark.jpg" className="mb-2 sm:mb-0 sm:mr-3" />
                <p>Im Studio & auf der Webseite digitale Verträge erstellen</p>
              </div>
              <div className="flex flex-col items-center font-medium mb-4 sm:flex-row">
                <img src="/checkmark.jpg" className="mb-2 sm:mb-0 sm:mr-3" />
                <p>
                  Vertrags-PDF wird direkt per E-Mail versandt & im
                  Admin-Bereich gespeichert
                </p>
              </div>
              <div className="flex flex-col items-center font-medium mb-4 sm:flex-row">
                <img src="/checkmark.jpg" className="mb-2 sm:mb-0 sm:mr-3" />
                <p>
                  Neue Mitglieder können direkt in deine Studiosoftware
                  exportiert
                </p>
                werden
              </div>
            </div>
          </div>
          <div className="flex-1 hidden sm:block">
            <div className="relative w-full h-full ">
              <div className="sm:absolute sm:transform sm:translate-x-40 sm:translate-y-20 sm:rotate-12 sm:scale-110">
                <img src="/homepage-vertrag-blau.jpg" width={600} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Index;
