import { serialize, parse, CookieSerializeOptions } from "cookie";
import { NextApiResponse, NextApiRequest } from "next";

const TOKEN_NAME = "token";
const REFRESH_TOKEN_NAME = "refresh-token";

export const MAX_AGE = 60 * 60 * 8; // 8 hours

type SetCookieProps = {
  res: NextApiResponse;
  name: string;
  value: string;
  options?: CookieSerializeOptions;
};
type CreateCookieProps = {
  name: string;
  value: string;
  options?: CookieSerializeOptions;
};

export function setCookie({ res, name, value, options }: SetCookieProps) {
  const cookie = serialize(name, value, options);

  res.setHeader("Set-Cookie", cookie);
}
export function createCookie({ name, value, options }: CreateCookieProps) {
  return serialize(name, value, options);
}
export function removeCookie(res: NextApiResponse, name: string) {
  const cookie = serialize(name, "", {
    maxAge: -1,
    path: "/",
  });

  res.setHeader("Set-Cookie", cookie);
}
export function setTokenCookies(
  res: NextApiResponse,
  token: string,
  refreshToken: string
) {
  const tokenCookie = serialize(TOKEN_NAME, token, {
    maxAge: MAX_AGE,
    expires: new Date(Date.now() + MAX_AGE * 1000),
    httpOnly: true,
    secure: process.env.NODE_ENV === "production",
    path: "/",
    sameSite: "lax",
  });
  const refreshTokenCookie = serialize(REFRESH_TOKEN_NAME, refreshToken, {
    maxAge: MAX_AGE,
    expires: new Date(Date.now() + MAX_AGE * 1000),
    httpOnly: true,
    secure: process.env.NODE_ENV === "production",
    path: "/",
    sameSite: "lax",
  });
  const loggedOutCookie = serialize("loggedOut", "0", {
    sameSite: "lax",
    path: "/",
    maxAge: 30 * 24 * 60 * 60 * 1000,
  });

  res.setHeader("Set-Cookie", [
    tokenCookie,
    refreshTokenCookie,
    loggedOutCookie,
  ]);
}

export function removeTokenCookies(res: NextApiResponse) {
  const tokenCookie = serialize(TOKEN_NAME, "", {
    maxAge: -1,
    path: "/",
  });
  const refreshTokenCookie = serialize(REFRESH_TOKEN_NAME, "", {
    maxAge: -1,
    path: "/",
  });
  const loggedOutCookie = serialize("loggedOut", "1", {
    sameSite: "lax",
    path: "/",
    maxAge: 30 * 24 * 60 * 60 * 1000,
  });

  res.setHeader("Set-Cookie", [
    tokenCookie,
    refreshTokenCookie,
    loggedOutCookie,
  ]);
}

export function parseCookies(req: NextApiRequest) {
  // For API Routes we don't need to parse the cookies.
  if (req.cookies) return req.cookies;

  // For pages we do need to parse the cookies.
  const cookie = req.headers?.cookie;
  return parse(cookie || "");
}

export function getTokenCookies(req: NextApiRequest) {
  const cookies = parseCookies(req);
  const token = cookies[TOKEN_NAME];
  const refreshToken = cookies[REFRESH_TOKEN_NAME];
  return { token, refreshToken };
}
